export default function FooterMarketingPages({
    divClassName = "",
    scrollToHowItWorks = null,
    scrollToFAQ = null,
}) {
    const handleClickHowItWorks = () => {
        if (scrollToHowItWorks) {
            scrollToHowItWorks();
        } else {
            window.location.href = "/#how-it-works";
        }
    };

    const handleClickFAQ = () => {
        if (scrollToFAQ) {
            scrollToFAQ();
        } else {
            window.location.href = "/#faq";
        }
    };

    return (
        <div
            className={
                `w-full relative z-0 mt-172 md:mt-144 lg:mt-172 ` + divClassName
            }
        >
            <div className="flex">
                <div className="grow">
                    <img
                        src="/images/landing/clouds/cloud-footer.png"
                        alt="Cloud"
                        className="h-[32rem] xl:h-auto w-auto xl:w-full top-8 left-0 absolute overflow-hidden object-cover z-10"
                    />
                </div>
                <div className="mt-72 md:mt-72 xl:mt-72 2xl:mt-100 z-10 w-full flex-none sm:flex">
                    <div className="ml-16 flex-1">
                        <img
                            src="/images/logo/wonder-machine-logo-horizontal.png"
                            alt="Wonder Machine"
                            className="h-12 sm:h-14 md:h-16 lg:h-18 w-auto object-cover overflow-visible"
                        />
                    </div>
                    <div className="text-right text-base mt-8 sm:mt-0 sm:text-lg mr-16 mb-8 shrink-0">
                        <div className="grid grid-cols-2 sm:grid-cols-2 gap-1">
                            <ul className="w-48">
                                <li>
                                    <a href={route("privacy-policy")}>
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href={route("refund-policy")}>
                                        Refund Policy
                                    </a>
                                </li>
                                <li>
                                    <a href={route("terms-and-conditions")}>
                                        Terms &amp; Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href={route("pricing")}>Pricing</a>
                                </li>
                                <li>
                                    <a href={route("blog.index")}>Blog</a>
                                </li>
                            </ul>
                            <ul className="w-48">
                                <li>
                                    <span
                                        className="cursor-pointer"
                                        onClick={handleClickHowItWorks}
                                    >
                                        How it works
                                    </span>
                                </li>
                                <li>
                                    <a href={route("about")}>About Us</a>
                                </li>
                                <li>Examples</li>
                                <li>
                                    <span
                                        className="cursor-pointer"
                                        onClick={handleClickFAQ}
                                    >
                                        FAQ
                                    </span>
                                </li>
                                <li>
                                    <a href={route("contact")}>Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
